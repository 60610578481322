html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  background-color: #282c34;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App {
  background: url("/assets/img/bg_2.png") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  /* min-height: 100vh; */

  display: flex;
  flex-direction: column;
  align-items: stretch;
}

main {
  flex-grow: 1;
}

header,
main,
footer {
  flex-shrink: 0;
}

.logo {
  margin: 0.5em;
}

.first-section-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;
}

section {
  padding: 5%;
  padding-bottom: 3%;
}
/* 
#section2 {
  padding-top: 2%;
} */

section.full-height {
  min-height: 70vh;
}

.first-section {
  margin-top: 5%;
}

.tagline {
  font-size: 3rem;
  margin: 0;
}

.sub-explanations {
  font-size: 0.9em;
  margin-top: 0.2em;
}

.explanations {
  margin-top: 0.5em;
}

.newsletter-form {
  margin-top: 1em;
}

a {
  color: white;
}

.contact-us {
  margin-top: 2em;
}

.newsletter-form > input,
button {
  font-size: calc(10px + 1.1vmin);
  padding: 0.8em;
  border: none;
  border-radius: 4px;
  margin-right: 1.2em;
}

.newsletter-form > button {
  padding-left: 1.3em;
  padding-right: 1.3em;
}

footer {
  background-color: #21232bcc;
  font-size: medium;
  text-align: center;
  padding: 1em;
  padding-bottom: 1.5em;
}

address {
  font-style: unset;
}

footer ul {
  list-style: none;
  padding: 0;
}

footer li {
  display: inline;
  padding: 0.5em;
  margin: 1em;
}

footer .fa-15x {
  font-size: 1.5em;
}

footer p {
  margin: 0;
}

footer a {
  text-decoration: none;
}

.thin {
  font-weight: 100;
}

.light {
  font-weight: 300;
}

.italic {
  font-style: italic;
}

.bold {
  font-weight: bold;
}

.ip-blue {
  color: #60eaea;
}

.w-56 {
  width: 56vw;
}

.w-70 {
  width: 70vw;
}

.w-75 {
  width: 75vw;
}

.w-08 {
  width: 80%;
}

.mg-top-0 {
  margin-top: 0;
}

.mg-tp-3 {
  margin-top: 3rem;
}

.mg-top-06 {
  margin-top: 0.6em;
}

.mg-top-08 {
  margin-top: 0.8em;
}

.mg-top-09 {
  margin-top: 0.9em;
}

.mg-bottom-0 {
  margin-bottom: 0;
}

input[type="submit"] {
  /* width: 95%; */
  margin-top: 1em;
  margin-right: 0;
  /* padding-left: 3em; */
  /* padding-right: 3em; */
}

input.ip-blue,
button.ip-blue {
  color: white;
  background-color: #50c2c2;
}

input.ip-blue:hover,
button.ip-blue:hover {
  background-color: #60eaea;
}

input[type="submit"]:disabled,
button.ip-blue:disabled {
  background-color: #00abab;
  cursor: not-allowed;
}

input[type="submit"] {
  cursor: pointer;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.full-height {
  min-height: 80vh;
}

.bg-white {
  background-color: white;
  color: #282c34;
}

.bg-f8 {
  background-color: #f8f8f8;
  color: #282c34;
}

.no-bullet {
  list-style: none;
}

ul.inline {
  padding: 0;
}

ul.inline > li {
  display: inline;
}

.inline {
  display: inline;
}

.references-logo-list li {
  margin-left: 0;
  margin-right: 4rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.mg-1 {
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.mg-tp-2 {
  margin-top: 2rem;
}

.mg-bt-0 {
  margin-bottom: 0;
}

.mg-bt-1 {
  margin-bottom: 1rem;
}

.mg-bt-2 {
  margin-bottom: 2rem;
}

.mg-bt-3 {
  margin-bottom: 3rem;
}

.mg-lf-2 {
  margin-left: 2rem;
}

.img-li {
  max-height: 70px;
}

@media (max-width: 576px) {
  .img-li {
    max-height: 30px;
  }

  .sm-hide.sm-hide {
    display: none;
  }
}

@media (max-width: 768px) {
  .img-li {
    max-height: 50px;
  }

  .references-logo-list li {
    margin-right: 2rem;
  }
}

.section-center {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}

section h2 {
  /* text-align: center; */
  /* margin-bottom: 2em; */
}

.flex {
  display: flex;
}
.wrap {
  flex-wrap: wrap;
}

.items-center {
  align-items: center;
}
.jc-between {
  justify-content: space-between;
}

.jc-around {
  justify-content: space-around;
}

.jc-evenly {
  justify-content: space-evenly;
}

.jc-center {
  justify-content: center;
}

.size-07 {
  font-size: 0.7em;
}

.size-08 {
  font-size: 0.8em;
}

.size-09 {
  font-size: 0.9em;
}

header ul {
  margin-right: 1em;
}

header li {
  /* padding: 0.5em; */
  margin: 0.5em;
}

.black {
  color: #282c34;
}

.blue-linkedin {
  color: #0274b3;
}

.decoration-none {
  text-decoration: none;
}
/* a i.black {
  color: #282c34;
} */

.text-center {
  text-align: center;
}

.dark-green {
  color: #0097a7;
}

img.rounded {
  border-radius: 50%;
}

.hidden {
  visibility: none;
}

.fixed {
  position: fixed;
}

#team .people-card {
  margin: 1em;
  flex-grow: 1;
  flex-basis: 0;
}

#team ul {
  padding: 0;
}

#team img {
  max-width: 170px;
}

@media (max-width: 576px) {
  #team img {
    max-width: 150px;
  }
}

ul.img-bullets > li {
  display: flex;
  align-items: center;
}

ul.img-bullets .bullet {
  margin: 1rem 1rem 1rem 0rem;
}

ul.img-bullets img {
  max-width: 4rem;
}

.bullet-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  background-color: #50c2c2;
  color: white;
}

.bullet p {
  margin: 0;
}

@media (max-width: 576px) {
  ul.img-bullets {
    padding: 0;
  }

  .bullet-icon {
    width: 3rem;
    height: 3rem;
  }
}

#contact {
  padding-bottom: 8rem;
}

@media (min-width: 768px) {
  .explanations {
    width: 70%;
  }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  input[name="email"] {
    width: 40%;
  }
  input[type="submit"] {
    padding-left: 3em;
    padding-right: 3em;
    /* width: 30%; */
  }
}

/* // Medium devices (tablets, 768px and up) */
@media (max-width: 992px) {
  input[name="email"] {
    width: 50%;
  }
  input[type="submit"] {
    width: 30%;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 768px) {
  input[name="email"] {
    width: 50%;
  }
  input[type="submit"] {
    width: 30%;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 576px) {
  .sm-w80 {
    width: 80vw;
  }

  .sm-w100 {
    width: 100vw;
  }

  .sm-w80p {
    width: 100%;
  }

  .sm-larger-txt {
    font-size: larger;
  }

  .tagline {
    font-size: 2rem;
  }

  input[name="email"] {
    width: 90%;
  }

  input[type="submit"] {
    width: 95%;
  }
}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  input[name="email"] {
    width: 40%;
  }
  input[type="submit"] {
    /* width: 30%; */
  }
  .explanations {
    width: 60%;
  }
}
