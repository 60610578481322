header {
  width: 100vw;
}

header.fixed {
  top: 0;
  opacity: 0;

  -webkit-transition: opacity 0.5s ease-in;
  -moz-transition: opacity 0.5s ease-in;
  -ms-transition: opacity 0.5s ease-in;
  -o-transition: opacity 0.5s ease-in;
  transition: opacity 0.5s ease-in;
}

header.load {
  opacity: 1;
}
header.fixed i {
  color: #282c34;
}

.header a {
  text-decoration: none;
  font-size: 1.3rem;
  color: white;
}

.header.dark {
  background-color: white;
  box-shadow: 0px -1px 5px black;
}

.header.dark a {
  color: #282c34;
  /* background-color: white; */
}

@media (max-width: 576px) {
  .header {
    width: 100vw;
  }
}
